<template>
  <v-timeline-item
    :color="color"
    small
    right
  >
    <div class="py-3">
      <h2 class="headline font-weight-light mb-3 red--text fill-width d-flex justify-space-between">
        <span>{{ title }}</span>
        <span v-if="created" class="text-caption">{{ created }}</span>
      </h2>
      <div v-html="html">&nbsp;</div>
    </div>
  </v-timeline-item>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    html: {
      type: String,
      default: '',
      required: true
    },
    created: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
