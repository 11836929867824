<template>
  <layout cards>
    <template #title>
      <h4 class="text-h4 text-center">
        Профориентационный проект<br>
        «Содействие успешной социализации подростков»
      </h4>
    </template>
    <div v-if="newsLoading" class="mt-16 text-center">
      <v-progress-circular
        indeterminate
        color="secondary"
        :size="100"
      />
    </div>
    <v-timeline
      v-else-if="areaNews.length"
      dense
      clipped
      class="mt-5"
    >
      <template v-for="(item, index) in areaNews || []">
        <news-item
          v-if="index < 2"
          :key="item.created"
          :title="item.title"
          :html="item.html"
          :created="item.created"
          color="accent"
        />
      </template>
      <v-timeline-item
        v-if="areaNews.length > 2"
        key="all_news"
        hide-dot
        small
        right
        class="my-4"
      >
        <v-dialog
          v-model="newsDialog"
          scrollable
        >
          <template #activator="{ on }">
            <v-btn outlined v-on="on">Открыть все сообщения</v-btn>
          </template>
          <v-card>
            <v-toolbar
              dense
              dark
              color="orange"
            >
              <v-toolbar-title>Информационные сообщения</v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="newsDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-timeline
                dense
                clipped
              >
                <template v-for="item in areaNews || []">
                  <news-item
                    :key="item.created"
                    :title="item.title"
                    :html="item.html"
                    :created="item.created"
                  />
                </template>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-timeline-item>
    </v-timeline>
  </layout>
</template>

<script>
import Layout from '@/views/project/layouts/Layout'
import NewsItem from '@/components/NewsItem'

export default {
  name: 'Main',
  components: { NewsItem, Layout },
  data: () => ({
    newsDialog: false,
    newsLoading: false,
    news: []
  }),
  computed: {
    areaNews () {
      return this.news[this.$store.getters['project/areaId']] || []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      if (this.newsLoading) {
        return
      }
      this.newsLoading = true
      this.$axios.get('/api/project/news').then(({ data }) => {
        if (data.status !== 200) {
          throw new Error(data.message || 'Возникла непредвиденная ошибка')
        }
        this.news = data.news || []
      }).catch(({ message }) => {
        this.news = []
        this.$toasted.error(message)
      }).finally(() => {
        this.newsLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
