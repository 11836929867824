<template>
  <area-documents-layout url="/api/project/about">
    <template #before>
      <h3 class="text-h5 mt-6 mb-3">О проекте</h3>
    </template>
    <template #after>
      <h3 class="text-h5 mt-6 mb-3">Цель</h3>
      <p>
        Создать систему эффективной профориентации в общеобразовательном учреждении,
        способствующую формированию профессионального самоопределения подростков в соответствии с их способностями,
        индивидуальными особенностями, с учётом запроса современного общества в востребованных специалистах.
      </p>

      <h3 class="text-h5 mt-6 mb-3">Задачи</h3>
      <ol>
        <li>
          Содействие формированию у обучающихся более чётких представлений о своих предпочтениях,
          склонностях и возможностях.
        </li>
        <li>
          Повышение уровня компетентности обучающихся посредством вооружения их соответствующими знаниями и умениями,
          расширения границ самовоспитания, пробуждения потребности в самосовершенствовании.
        </li>
        <li>
          Формирование у старшеклассников положительного отношения к себе,
          уверенности в своих способностях применительно к своей будущей профессии.
        </li>
        <li>
          Ознакомление обучающихся с широким спектром профессий и специальностей,
          спецификой профессиональной деятельности, новыми формами организации труда в условиях конкуренции.
        </li>
      </ol>

      <h3 class="text-h5 mt-6 mb-3">Сроки проекта</h3>
      <ul>
        <li><b>Создание решения:</b> январь 2017</li>
        <li><b>Сопровождение и развитие: февраль 2017 – настоящее время</b></li>
      </ul>

      <h3 class="text-h5 mt-6 mb-3">Некоторые данные о проекте</h3>
      <ul>
        <li><b>Количество школ участников:</b> 25</li>
        <li><b>Количество сотрудников школ, привлечённых к работе в проекте:</b> более 50</li>
        <li><b>Количество пользователей:</b> более 1500</li>
        <li><b>Количество направлений проекта:</b> 3</li>
      </ul>
    </template>
  </area-documents-layout>
</template>

<script>
import AreaDocumentsLayout from '@/views/project/layouts/AreaDocumentsLayout'

export default {
  name: 'About',
  components: { AreaDocumentsLayout }
}
</script>
